import gql from 'graphql-tag';

import { vendorPartial } from './VendorFragment';
import { VendorCampaignBase } from './VendorCampaignFragment';

const VendorLayerDetailFragment = gql`
  fragment VendorLayerDetailFragment on VendorLayer {
    __typename
    id
    layerID
    vendorID
    networkID
    brokerID
    destinations
    description
    tags
    verticalType
    kindOfLead
    addonPrice
    myAgentDestinations
    vendor {
      ${vendorPartial}
    }
    minimumRefresh
    autoProcessThreshold
    autoProcess
    vendorSourceCampaign
    vendorSourceCampaignMap
    vendorPrice
    maxDemandUnsoldAccepted
    splitCount
    enhanceProfile
    rejectDisconnected
    rejectDNC
    fixedBidPrice
    apiType
    maxSpendPerHour
    maxSpend
    pingPostEnabled
    pingPostType
    rejectDuplicates
    refundEligible
    refundMaxAge
    refundMaxAgeIncrement
    refundMaxAgeDateType
    pendingLeads
    errorLeads
    mpVendorCampaignName
    acceptsTermsAndConditions
    isCampaignDestination
    skipActiveDemandSubmit
    overideDuplicateChecks
    allowMLOverride
    allowMLearning
    smartPriceAcceptence
    dupPriceThreashold
    dupPriceThreasholdLookBack
    allowAPOnPing
    overrideBlockedAttributeFields{
      label
      value
    }
    overrideBlockedValues{
      label
      value
    }
    allowSlices
    allowSplits
    verifyDestination
    isUpdateEligible
    delaySecondsForUpdate
    mlTargetConversionRate
    allowMLPriceOverride
    mlTargetMinPrice
    mlTargetMaxPrice
    minAcceptenceBid
    useEventsMLModel
    skipGTBDuplicate
    liveTransferAnswerTime
    liveTransferBatchCycleCount
    liveTransferBatchSize
    liveTransferDropCall
    liveTransferEmail
    liveTransferIntroGreeting,
    liveTransferNotificationsOnly
    liveTransferOverflowRedirectCall
    liveTransferPhoneNumber
    liveTransferRejectNoDataCalls
    liveTransferScript
    liveTransferTierID
    liveTransferVoiceMailUrl
    liveTransferVoip
    liveTransferWaitUrl
    liveTransfersEnabled
    liveTransferEarlyHangUpLayerID
    postAttributes
    pingAttributes
    attributeRanges{
      age {
        nextSegment
        lowestBound
        highestBound
      }
      income {
        nextSegment
        lowestBound
        highestBound
      }
      household {
        nextSegment
        lowestBound
        highestBound
      }
    }
    filters {
        __typename
        ... on VendorLayerFilterBase {
          type
        }
        ... on VendorLayerAddOnFilter{
          attributes:attribute
          resultAttribute
          include
          level
          type
          settings{
            claimCert
            priceOverride
            storeAnalytics
            ruleID
          }
        }
        ... on VendorLayerVerifyFilter{
          attributes:attribute
          attributeType
          type
          include
        }
        ... on VendorLayerSystemFilter{
          type
          functionName
          include
          rejectMessage
          skipMissing
        }
        ... on VendorLayerTimeFilter{
          type
          attribute
          min
          max
          include
          rejectType
          bidType
          bid
          skipMissing
        }
        ... on VendorLayerStaticFilter{
          include
          level
          values
          attribute
          type
          skipMissing
        }
        ... on VendorLayerMultiFilter{
          attribute
          resultAttribute
          ruleID
          valuesInt
          include
          type
          skipMissing
        }
        ... on VendorLayerRangeFilter{
          attribute
          include
          min
          max
          type
          skipMissing
          skipMissingOnPing
        }
        ... on VendorLayerDynamicFilter {
          level
          type
          attribute
          resultAttribute
          minimumRefresh
          include
          skipMissing
          allowOnPing
          settings{
            isCopy
          }
        }
    }
    apBidEnhancmentEnabled
    apSettings{
        apBidEnhancmentDynamic
        apPercentageEnabled
        apWPMMin
        apWPMMax
        apKPMMin
        apKPMMax
        apBidPrice
        apBidPercentage
    }
  }
`;

export default VendorLayerDetailFragment;
